


















































import { Component, Vue, Prop } from "vue-property-decorator";
import { primaryTitle, tertiaryBody } from "@/helpers/styleClassHelpers";
import CustomButton from "./Button.vue";
import GuideBalloon from "./GuideBalloon.vue";
import { showSuccessAlert } from "@/helpers";

@Component({
	components: {
		"custom-button": CustomButton,
		GuideBalloon,
	},
})
export default class RatingValueStar extends Vue {
	@Prop({ default: () => ({}) }) appointment!: any;

	primaryTitle = primaryTitle;
	tertiaryBody = tertiaryBody;

	stars = 1;

	get solutionName() {
		return this.appointment.solution?.name;
	}

	get solutionCategoryName() {
		return this.appointment.solution?.category?.name;
	}

	saveRating(rate: number) {
		this.$emit("saveAction", "approved", rate, this.appointment);
	}

	copyText() {
		navigator.clipboard.writeText(
			`No último contato foi orientado ${this.solutionCategoryName}, como se sente hoje em relação a sua saúde? (Pior, Sem alteração ou Melhor)`,
		);
		showSuccessAlert("", "Texto copiado");
	}
}











































































import { Component, Vue, Prop } from "vue-property-decorator";
import { primaryBody, tertiaryBoldBody } from "@/helpers/styleClassHelpers";

@Component({
	components: {},
})
export default class Guides extends Vue {
	@Prop({ default: () => [] }) guides!: any[];

	tertiaryBoldBody = tertiaryBoldBody;
	primaryBody = primaryBody;

	currentId = this.$route.params.id;

	openGuide(index: number) {
		if (this.guides[index]?.totalData !== 0) {
			this.guides[index].isOpen = !this.guides[index].isOpen;
		}
	}

	showHelp(index: number) {
		this.guides[index].displayingHelp = true;
	}

	hideHelp(index: number) {
		this.guides[index].displayingHelp = false;
	}
}















































import { Component, Vue, Prop } from "vue-property-decorator";
import CustomButton from "./Button.vue";
import GuideBalloon from "./GuideBalloon.vue";
import { findNextWorkDayAfterDays, readableDay, toIsoDate } from "@/helpers/calendar";
import Calendar from "@/layouts/components/Calendar.vue";
import Button from "@/layouts/components/Button.vue";

const nextWorkDay = findNextWorkDayAfterDays(toIsoDate(new Date()), 7);

@Component({
	components: {
		"custom-button": CustomButton,
		GuideBalloon,
		Calendar,
		Button,
	},
})
export default class ReturnDateUpdate extends Vue {
	@Prop({ default: () => ({}) }) appointment!: any;
	@Prop({ default: () => null }) onModalClosed!: (shouldClearTheSearch?: boolean) => void;
	@Prop({ default: () => null }) onReloadParent!: Function;

	isCalendarOpen = false;
	selectedReturnDate = nextWorkDay;
	hasReturnDate = true;
	isLoading = false;

	get solutionName() {
		return this.appointment.solution?.name;
	}

	get solutionCategoryName() {
		return this.appointment.solution?.category?.name;
	}

	async updateReturnDate() {
		this.$emit("saveAction", "updateReturnDate", { returnDate: this.selectedReturnDate }, this.appointment);
	}

	get todayIsoDate() {
		return toIsoDate(new Date());
	}

	get readableReturnDate() {
		return readableDay(this.selectedReturnDate);
	}
}




























































import { Component, Prop, Vue } from "vue-property-decorator";
import { primaryBody, secondaryBody } from "@/helpers/styleClassHelpers";
import BaseModal from "./BaseModal.vue";
import RatingValueStar from "./RatingValueStar.vue";
import RatingEmoji from "./RatingEmoji.vue";
import ReturnDateUpdate from "./ReturnDateUpdate.vue";
import RefusedAppointment from "./RefusedAppointment.vue";
import { formatDayMonthYear, getAppointmentVisualTextArray } from "@/helpers/utils";

@Component({
	components: {
		"base-modal": BaseModal,
	},
})
export default class ActionPlan extends Vue {
	@Prop({ default: () => [] }) appointments!: any[];
	@Prop({ default: () => null }) onAction!: Function;

	primaryBody = primaryBody;
	secondaryBody = secondaryBody;

	formatDayMonthYear = formatDayMonthYear;
	getAppointmentVisualTextArray = getAppointmentVisualTextArray;

	modalIsOpen = false;
	currentAppointment = {};
	currentModal = RatingValueStar as any;
	currentProps = {
		appointment: this.currentAppointment,
	};

	closedModal() {
		this.modalIsOpen = false;
	}

	onActionLocal(action: number, value: any, appointment: any) {
		this.modalIsOpen = false;
		this.onAction(action, value, appointment);
	}

	openModalRefused(appointment: any) {
		this.currentAppointment = appointment;
		this.currentModal = RefusedAppointment;
		this.currentProps = {
			appointment: this.currentAppointment,
		};
		this.modalIsOpen = true;
	}

	openModalApproved(appointment: any) {
		this.currentAppointment = appointment;
		this.currentModal = ["Orientações", "Sem necessidade"].includes(appointment.solution.category.name)
			? RatingEmoji
			: RatingValueStar;
		this.currentProps = {
			appointment: this.currentAppointment,
		};
		this.modalIsOpen = true;
	}

	openModalUpdateReturnDate(appointment: any) {
		this.currentAppointment = appointment;
		this.currentModal = ReturnDateUpdate;
		this.currentProps = {
			appointment: this.currentAppointment,
		};
		this.modalIsOpen = true;
	}

	openAttachment(url: string) {
		window.open(url, "_blank");
	}
}

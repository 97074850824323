












import { Component, Prop, Vue } from "vue-property-decorator";
import CustomButton from "./Button.vue";
import CustomInput from "./Input.vue";
import CustomTextArea from "./TextArea.vue";

@Component({
	components: {
		CustomInput,
		CustomTextArea,
		"custom-button": CustomButton,
	},
})
export default class RefusedAppointment extends Vue {
	@Prop({ default: () => ({}) }) appointment!: any;

	dataAppointment = {
		reason: "",
		description: "",
	};

	saveRefused() {
		this.$emit("saveAction", "refused", this.dataAppointment, this.appointment);
	}
}
































































































import { secondaryBody, tertiaryBody } from "@/helpers/styleClassHelpers";
import { formatDayMonthYear, getAppointmentVisualTextArray } from "@/helpers/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import solutionAppointment from "../../api/solution_appointment_app.model";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import { BOverlay } from "bootstrap-vue";

@Component({
	components: {
		BOverlay,
	},
})
export default class AppointmentsHistory extends Vue {
	@Prop({ default: () => [] }) appointments!: any[];
	@Prop({ default: 3 }) solutionsDisplayed!: number;

	secondaryBody = secondaryBody;
	tertiaryBody = tertiaryBody;

	formatDayMonthYear = formatDayMonthYear;
	getAppointmentVisualTextArray = getAppointmentVisualTextArray;

	currentPage = 1;
	currentIndexAdditionalInformation = -1;

	isLoading = false;

	get totalPages() {
		return Math.ceil(this.appointments.length / this.solutionsDisplayed);
	}

	get itemsDisplayed() {
		return this.appointments.filter(
			(_, index) =>
				index >= this.currentPage * this.solutionsDisplayed - this.solutionsDisplayed &&
				index < this.currentPage * this.solutionsDisplayed,
		);
	}

	nextPage() {
		if (this.currentPage + 1 <= this.totalPages) {
			this.currentPage++;
		}
	}

	previousPage() {
		if (this.currentPage !== 1) {
			this.currentPage--;
		}
	}

	formatNumber(number: number) {
		if (number) {
			return number.toFixed(1);
		} else {
			return "-";
		}
	}

	showHelp(index: number) {
		this.currentIndexAdditionalInformation = index;
	}

	hideHelp() {
		this.currentIndexAdditionalInformation = -1;
	}

	async uploadFile(event: any, appointment: any) {
		const [currentFileUpload] = event.target.files;

		this.isLoading = true;

		try {
			const fileUrl = await solutionAppointment.upload(currentFileUpload);

			const response = await solutionAppointment.patch(appointment.id, "setAttachmentUrl", {
				attachmentUrl: fileUrl.fileName,
			});

			appointment.attachmentUrl = response.attachmentUrl;

			showSuccessAlert("O arquivo foi anexado com sucesso");
		} catch (error: any) {
			showErrorAlert(error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isLoading = false;
		}
	}

	openAttachment(url: string) {
		window.open(url, "_blank");
	}
}
